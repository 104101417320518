<!--  -->
<template>
  <div id="decennial">
    <Header></Header>
    <div class="banner"></div>
    <div class="introduce wow animate__animated animate__fadeIn">
      <div class="title">十周年</div>
      <div class="line"></div>
      <img src="@/assets/image/decennial/theme.jpg" alt="" />
    </div>
    <div class="timeline">
      <el-timeline>
        <el-timeline-item timestamp="2023" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>
              全自动推片染色机、全自动血液细胞形态AI扫描分析系统、DNA倍体特殊染色机全线上市，公司正式迈入产品多元化新时代
            </h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2022" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>数字切片扫描仪系列获批，远程病理诊断解决方案上市</h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2021" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>
              吉米娜CR-SE600上市，与金域医学等多家全国型第三方实验室达成深度合作
            </h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2020" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>
              全自动核型分析仪上市，首都医科大学附属北京胸科医院等标杆医院建立
            </h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2019" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>产品快速布局全国，多中心实验圆满完成，两癌筛查管理平台上市</h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2018" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>四代产品吉米娜上市，公司搬迁新址</h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2017" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>三代产品鹰眼上市</h4>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2013" placement="top">
          <el-card class="wow animate__animated animate__fadeInUp">
            <h4>公司成立</h4>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="send-word wow animate__animated animate__fadeIn">
      <div class="title">创始人寄语</div>
      <div class="line"></div>
      <img src="@/assets/image/decennial/founder.jpg" alt="" />
      <div class="text">湖南品信生物工程有限公司创始人<span>刘剑锋</span></div>
    </div>
    <div
      class="blessing wow animate__animated animate__fadeInUp"
      data-wow-delay="0.5s"
    >
      亲爱的朋友们：<br />
      <p style="text-indent: 2em">
        回首公司十年发展点滴，我们从最开始的几个人/十几人的团队到现在近200人的规模，从最开始的第一台“鹰眼-全自动细胞DNA定量分析系统”到现在建立了“试剂耗材、制片染色、AI分析、远程管理平台”全产品线，一路上经历了几次大的挑战，也完成了无数次蜕变，但变的只是时间，不变的是我们的初心。十年来，我们始终秉持“品为先
        信至上”的理念，脚踏实地，不断探索，一步一个脚印地走了过来。十年来，我们以“病理诊断”为核心，通过“AI+数字化医疗”的双轮驱动
        ，致力于为疾病的早期筛查和诊断，提供“自动化、数字化、智能化、平台化”的完整解决方案。
      </p>
      <p style="text-indent: 2em">十年岁月峥嵘，十年春华秋实。</p>
      <p style="text-indent: 2em">
        十年的发展，我们取得了一些成绩，获得了多个产品注册证，拥有多个国家及省市创新平台，与10多家大型三甲医院开展科研合作，打造产学研用一体化发展。同时，布局了全国市场，打造了300+标杆医院，参与甘肃省两癌、湖南省两癌等政府筛查项目，参与了国家5G+健康平台试点项目，并启动了血液、微生物、甲状腺、尿液等新领域的创新研发，在病理行业建立了一定品牌知名度。这些都为公司的下一阶段的发展打下了良好的基础。可以说，公司这十年发展离不开各位股东、各位专家、合作伙伴的大力支持。感谢我们一路上的伙伴和朋友们，没有你们的支持和厚爱，就没有我们品信的今天，也希望在未来大家能一如既往地支持我们品信，彼此深度合作，一起为我国病理行业的发展贡献一份力量！
        在时间的长河中，十年不过是浪花一朵。但对发展中的企业而言，犹如逆水行舟，不进则退。现在，公司进入到快速发展关键阶段，未来挑战与机遇并存。从外部环境来看，后疫情时代，经济复苏不及预期，医疗行业集采的不断推进，企业之间竞争越来越激烈，但我们也看到随着我国老龄化加剧，癌症的发病率增长，癌症早筛及诊断需求激增，其次，国家对两癌筛查的大力支持，对人工智能发展的鼓励，都是利好企业未来的发展，可以说，我们在一个对的赛道上！未来，我们品信也将继续攻坚克难、迎难而上、攀登下一座高峰！
        最后，我代表公司全体员工祝大家身体健康、工作顺利、万事如意！谢谢！
      </p>
    </div>
    <div class="partners">
      <div class="theme wow animate__animated animate__fadeInLeft">
        常年与国内多家大型医院服务合作
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {},
};
</script>
    
<style lang="scss">
#decennial {
  padding-top: 3.75rem;
  .banner {
    width: 100%;
    height: 12rem;
    background-image: url("../../../assets/image/decennial/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    animation-name: scaleDraw; /*关键帧名称*/
    animation-timing-function: ease; /*动画的速度曲线*/
    animation-duration: 2s; /*动画所花费的时间*/
  }
  .introduce {
    padding: 1.5rem 1.25rem;
    .title {
      text-align: center;
      font-size: 1.5rem;
      font-family: "SourceHanSansSC-Medium";
    }
    .line {
      width: 5rem;
      height: 0.375rem;
      background: #0b847b;
      margin: 0.5rem auto 1.5rem;
    }
    img {
      width: 100%;
    }
  }
  .timeline {
    padding: 1.25rem;
    .el-timeline-item__timestamp {
      font-size: 1.2rem;
      color: #0b847b;
      font-family: "SourceHanSansSC-Medium";
    }
    .el-card__body {
    }
  }
  .send-word {
    padding: 1.5rem 1.25rem;
    .title {
      text-align: center;
      font-size: 1.5rem;
      font-family: "SourceHanSansSC-Medium";
    }
    .line {
      width: 9rem;
      height: 0.375rem;
      background: #0b847b;
      margin: 0.5rem auto 1.5rem;
    }
    img {
      width: 100%;
    }
    .text {
      text-align: center;
      font-size: 0.8rem;
      font-family: "OPlusSans3-Regular";
      padding-top: 1rem;
      span {
        display: inline-block;
        margin-left: 0.8rem;
      }
    }
  }
  .blessing {
    padding: 0 1.25rem 1.25rem;
    font-size: 1rem;
    line-height: 2.2rem;
    font-family: "OPlusSans3-Regular";
  }
  .partners {
    width: 100%;
    height: 18rem;
    background-image: url("../../../assets/mobile_img/decennial/partners.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    .theme {
      width: 100%;
      font-size: 1.2rem;
      font-family: "OPlusSans3-Regular";
      font-weight: 600;
      text-align: center;
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>